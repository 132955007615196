@import "../config";

footer {
  background-color: $color-white;

  .piFooter {
    padding-top: $spacing-xl;
    border-top: 1px solid $color-border;
    color: $color-text-faded;
    font-size: $font-size-s;

    .col {
      padding-bottom: $spacing-m;
    }

    ul {
      list-style-type: none;
      margin: 0;

      li {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
          text-decoration: underline;
        }
      }
    }

    @include breakpoint(medium) {
      display: flex;
      justify-content: space-between;

      .col {
        min-width: 20%;
      }
    }
  }
}
