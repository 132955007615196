@import "../config";
@import "../common/mixins";

$_btn-height: rem(50);

.piBtn {
  display: inline-flex;
  height: $_btn-height;
  justify-content: center;
  align-items: center;
  padding: 0 $spacing-xl;
  line-height: 1;
  border: 1px solid $color-text;
  border-radius: 30px;
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  text-align: center;
  transition: all .3s;

  &:hover {
    background-color: $color-accent;
    border-color: $color-accent;
    color: $color-white;
  }
}
