@import "../config";

.piLeistungen {
  &__schwerpunkte,
  &__hoai {
    list-style: none;
    padding: 0;
    margin: $spacing-s 0 0;

    li {
      border-bottom: 1px solid $color-border;
      padding: $spacing-s 0;

      &:nth-child(-n + 1) {
        border-top: 1px solid $color-border;
      }
    }
  }

  &__hoai {
    /* stylelint-disable-next-line no-descending-specificity */
    li {
      display: flex;
      counter-increment: li;

      &::before {
        content: counter(li);
        color: $color-text-bg;
        font-weight: $font-weight-bold;
        display: block;
        width: 1em;
        margin: 0 rem(20) 0 rem(5);
      }
    }
  }

  &__tragwerk {
    &__project {
      margin-top: $spacing-l;
    }
  }

  &__konstruktionen {
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      /* stylelint-disable-next-line no-descending-specificity */
      li {
        text-align: center;
        padding: $spacing-l;
        width: 100%;
        border: 1px solid $color-border;
        margin-top: -1px;
      }
    }
  }

  &__bim {
    &__image {
      margin-top: $spacing-l;

      img {
        width: 100%;
      }
    }
  }

  @include breakpoint(small only) {
    &__moods {
      div:nth-child(2) {
        display: none;
      }
    }
  }

  @include breakpoint(medium) {
    &__moods {
      display: flex;
      justify-content: space-between;

      > div {
        width: calc(50% - 10px);
      }
    }

    &__schwerpunkte {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: calc(50% - 10px);

        &:nth-child(-n + 2) {
          border-top: 1px solid $color-border;
        }
      }
    }

    &__tragwerk,
    &__konstruktionen {
      display: flex;
      justify-content: space-between;

      &__text {
        width: calc(70% - 50px);
      }

      &__project {
        width: 30%;
        margin-top: 0;

        .piProject {
          margin-top: 0;
        }
      }
    }

    &__konstruktionen {
      &__text {
        order: 2;
      }

      &__project {
        order: 1;
      }

      &__list {
        /* stylelint-disable-next-line no-descending-specificity */
        li {
          width: 50%;

          &:nth-child(2) {
            margin-left: -1px;
          }
        }
      }
    }

    &__bim {
      display: flex;
      justify-content: space-between;

      &__text {
        width: calc(50% - 50px);
      }

      &__image {
        margin-top: 0;
        width: 50%;
      }
    }
  }

  @include breakpoint(large) {
    &__schwerpunkte {
      li {
        width: calc(33.33% - 20px);

        &:nth-child(3) {
          border-top: 1px solid $color-border;
        }
      }
    }

    &__bim {
      display: flex;
      justify-content: space-between;

      &__text {
        width: calc(60% - 50px);
      }

      &__image {
        margin-top: 0;
        width: 40%;
      }
    }
  }
}
