@import "../config";

$_breakpoint-carousel: 1330px;
$_max-width: rem-calc(1400);

/* purgecss start ignore */
.piCarousel {
  $self: &;

  position: relative;
  //width: calc(100%);
  max-width: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  .swiper-container {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    // CSS Grid/Flexbox bug size workaround
    // @see https://github.com/kenwheeler/slick/issues/982
    min-height: 0;
    min-width: 0;
  }

  .swiper-wrapper {
    .ie9 & {
      overflow: hidden;
    }
  }

  .swiper-pagination {
    position: relative;
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
    text-align: center;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    width: rem(10);
    height: rem(10);
    margin: 0 3px !important;
    background: $color-text-bg;
    border-color: $color-text-bg;
    border-radius: 100%;
    opacity: 0.4;
    transition: all 0.3s;

    #{ $self }--one-item &,
    #{ $self }--two-items &,
    #{ $self }--three-items & {
      background: $color-text-bg;
      opacity: 0.4;
    }

    &:hover {
      background: $color-text;
      opacity: .6;
      cursor: pointer;

      #{ $self }--one-item &,
      #{ $self }--two-items &,
      #{ $self }--three-items & {
        background: $color-dark-gray;
      }
    }

    &:focus {
      outline: none;
    }

    &.swiper-pagination-bullet-active {
      background: $color-text-bg;
      opacity: 1;
      border: none;

      #{ $self }--one-item &,
      #{ $self }--two-items &,
      #{ $self }--three-items & {
        background: $color-text-bg;
        opacity: 1;
      }

      &:hover {
        #{ $self }--one-item &,
        #{ $self }--two-items &,
        #{ $self }--three-items & {
          background: $color-dark-gray;
        }
      }
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    left: $spacing-m;
  }

  .swiper-button-next {
    right: $spacing-m;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: rem(30);
    height: rem(30);
    top: 50%;
    margin-top: rem(-15);
    opacity: 1;
    z-index: $zindex-level-3;
    transition: opacity .3s;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url('../../img/chevron-white.svg');
    //display: none;
  }

  &--one-item,
  &--main-gallery {
    .swiper-button-prev,
    .swiper-button-next {
      background-image: url('../../img/chevron-white.svg');
    }
  }

  .image-copyright {
    position: absolute;
    right: 5px;
    bottom: 5px;
    text-align: right;
    padding: 0 5px;
    background-color: rgba(0,0,0,0.3);
    color: $color-white;
    font-size: $font-size-xxs;
  }

  &--main-gallery {
    .swiper-slide {
      .piImage {
        max-width: 100%;
        width: auto;
        height: 100%;
        object-fit: cover;
        img {
          object-fit: cover;
        }
      }
    }
  }

  &--one-item {
    .swiper-slide {
      .piImage {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  @include breakpoint(medium) {
    &--main-gallery {
      margin-bottom: $spacing-xl;

      .swiper-slide {
        width: auto;
        height: rem(400);
      }

      .swiper-button-prev,
      .swiper-button-next {
        &:not(.swiper-button-lock) {
          display: block;
        }
      }

      .swiper-pagination {
        position: absolute;
        left: 0;
        right: 0;
        padding-bottom: $spacing-s;
      }
    }

    &--one-item {
      margin-bottom: $spacing-xl;

      /* stylelint-disable no-descending-specificity */
      .swiper-button-prev,
      .swiper-button-next {
        &:not(.swiper-button-lock) {
          display: block;
        }
      }

      /* stylelint-enable */
      .swiper-pagination {
        position: absolute;
        left: 0;
        right: 0;
        padding-bottom: $spacing-s;
      }
    }

  }

  @include breakpoint(large) {
    &--main-gallery {
      .swiper-slide {
        height: rem(500);
      }
    }
  }

  @include breakpoint(xlarge) {
    &--main-gallery {
      .swiper-slide {
        height: rem(650);
      }
    }
  }

  @include breakpoint(small only) {
    .swiper-wrapper {
      display: flex;
      height: 300px;
      overflow-x: scroll;
      &.content {
        height: auto;
      }
    }
    .swiper-slide {
      width: auto;
      height: 100%;
      img {
        object-fit: cover;
      }
    }
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.noCarousel {
  max-width: $_max-width;
  width: 100%;
  margin: auto;
}

/* purgecss end ignore */
