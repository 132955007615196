@import '~foundation-sites/scss/util/util';
@import "common/mixins";

/* -----------------------------------------------------------------------------
/ COLOR PALETTE
/ -----------------------------------------------------------------------------

/ COLORS
/ http:/chir.ag/projects/name-that-color/ */

$_color-alto: #ddd;
$_color-drover: #fef8a8;
$_color-cod-gray: #1e1e1e;
$_color-caper: #caeaaf;
$_color-mandys-pink: #f2bab7;
$_color-geyser: #d2dbe0;
$_color-merino: #f8f2ec;
$_color-papaya-whip: #fff0d5;
$_color-gray: #666;
$_color-steel: #e6ecee;
$_color-chatmans-blues: #174f6e;
$_color-mystic: #e6ecee;

$color-monza: #cf0031;
$color-woody-brown: #422d31;
$color-pampas: #f3f1ee;
$color-westas: #dcd6d1;
$color-dusty-gray: #a09698;
$color-ebb: #edeae8;
$color-forest-green: #3a7328;
$color-tea: #c3b9b0;
$color-tumbleweed: #daaa79;
$color-westside: #f81;

/* DEDICATED COLORS */
$color-black: #000;
$color-white: #fff;
$color-ultra-light: #f9f8f6;
$color-yellow: $_color-drover;
$color-green: $_color-caper;
$color-light-gray: $_color-alto;
$color-dark-gray: $_color-cod-gray;
$color-pink: $_color-mandys-pink;
$color-steel: $_color-geyser;
$color-beige: $_color-merino;
$color-light-yellow: $_color-papaya-whip;

$color-accent: $_color-chatmans-blues;
$color-warm-brown: $color-woody-brown;
$color-warm-gray: $color-westas;
$color-light-brown: $color-tumbleweed;

$color-text: $color-dark-gray;
$color-text-faded: $_color-gray;
$color-lines: $color-light-gray;
$color-border: $color-light-gray;
$color-page: $color-beige;
$color-text-bg: $color-tea;
$color-bg: $color-steel;
$color-bg-light: $_color-mystic;

/* -----------------------------------------------------------------------------
/ SPACING
/ ----------------------------------------------------------------------------- */

$spacing-xxs: rem(5px);
$spacing-xs: rem(10px);
$spacing-s: rem(15px);
$spacing-m: rem(20px);
$spacing-l: rem(30px);
$spacing-xl: rem(40px);
$spacing-xxl: rem(50px);
$spacing-xxxl: rem(60px);

$spacing: (
  xxs : $spacing-xxs,
  xs : $spacing-xs,
  s : $spacing-s,
  m : $spacing-m,
  l : $spacing-l,
  xl : $spacing-xl,
  xxl : $spacing-xxl,
);

/* -----------------------------------------------------------------------------
/ LAYOUT
/ ----------------------------------------------------------------------------- */

$max-page-width: rem(1800);
$breakpoint-sublarge: 1260px;
$content-width: rem(1300);
$content-width-outside: rem(1600);
$content-wrapped-width: rem(1200);
$paragraph-wrapped: rem(640);
$el-width-menu-safe: calc(100% - 115px);

/* -----------------------------------------------------------------------------
/ TYPOGRAPHY
/ ----------------------------------------------------------------------------- */

$font-primary: 'europa', 'Helvetica Neue', Arial, sans-serif;
$font-decor: 'europa', Times, serif;

$font-size-xxs: 12px;
$font-size-xs: 14px;
$font-size-s: 15px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-xl: 20px;

$font-size-detail-s: 10px;
$font-size-detail-m: 12px;
$font-size-detail-l: rem(13px);

$font-size-title-xs: rem(20px);
$font-size-title-s: rem(24px);
$font-size-title-m: rem(28px);
$font-size-title-l: rem(30px);
$font-size-title-xl: 30px;
$font-size-title-xxl: rem(50px);

$line-height-small: 1.2;
$line-height-normal: 1.4;
$line-height-large: 1.8;

$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

$letter-spacing: rem(2);

/* -----------------------------------------------------------------------------
/ Z-INDICES
/ ----------------------------------------------------------------------------- */
$zindex-level-1: 250;
$zindex-level-2: 500;
$zindex-level-3: 750;
$zindex-level-4: 1750;
$zindex-modal: 1000;

/* -----------------------------------------------------------------------------
/ COMPONENTS
/ ----------------------------------------------------------------------------- */

$control-height: rem(50);
$control-height-reduced: rem(40);
$info-btn-size: 54px;
$info-btn-size--small: 38px;

$logo-large-width: rem(90);

$border-radius-s: 2px;
$border-radius-m: 4px;

@mixin divider($margintop) {
  &::after {
    content: '';
    display: block;
    width: 100%;
    max-width: $content-wrapped-width;
    height: 1px;
    margin: $margintop auto 0;
    border: none;
    background-size: 6px 1px;
  }
}

/* -----------------------------------------------------------------------------
/ FOUNDATION OVERRIDES
/ ----------------------------------------------------------------------------- */
$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1240px,
  xxlarge: 1800px,
);

$grid-column-gutter: (
  small: 20px,
  medium: 20px,
);
$grid-margin-gutters: (
  small: 20px,
  medium: 20px
);

/* Tabs */
$tab-margin: 0;
$tab-background: transparent;
$tab-background-active: transparent;
$tab-item-font-size: rem-calc(16);
$tab-item-background-hover: transparent;
$tab-item-padding: 1.25rem 1.5rem;
$tab-expand-max: 6;
$tab-content-background: transparent;
$tab-content-border: transparent;
$tab-content-padding: 1rem;

/* Reveals */
$reveal-border: 1px solid transparent !default;
$reveal-radius: 0;
$reveal-overlay-background: rgba($color-warm-brown, 0.96) !default;

/* Boxshadow  */
$boxshadow-regular: 0 10px 30px 0 rgba(0, 0, 0, 0.15);

/* Tooltip */
.tooltip {
  border-radius: $border-radius-m;
  background-color: $color-warm-brown;
  text-align: center;
  min-width: rem(200);

  &.top::before {
    border: solid rem(8);
    border-color: $color-warm-brown transparent transparent !important;
  }

  &.bottom::before {
    border: solid rem(8);
    border-color: transparent transparent $color-warm-brown !important;
  }

  &.left::before {
    border: solid rem(8);
    border-color: transparent transparent transparent $color-warm-brown !important;
  }

  &.right::before {
    border: solid rem(8);
    border-color: transparent $color-warm-brown transparent transparent !important;
  }
}
