@import "../config";

/* purgecss start ignore */

.piImagegrid {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  &__item {
    flex: 1 1 0;
    width: auto;

    img {
      width: 100%;
    }
    &__alt {
      padding: $spacing-xs 0;
      font-weight: $font-weight-medium;
      font-size: $font-size-s;
    }
  }
  @include breakpoint(small only) {
    flex-wrap: wrap;
    &__item {
        flex: 1 1 100%;
    }
  }
}

.piImageText {
  position: relative;

  &__text {
    position: relative;

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @include breakpoint(small only) {
    &--overlay {
      .piImageText {
        &__image {
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $color-black;
            opacity: 0.8;
          }
        }

        &__text {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }


  @include breakpoint(medium only) {
    &__text {
      .case-detail & {
        padding: $spacing-xl *2  0 0 0;
      }
    }
  }

  @include breakpoint(large) {
    display: flex;
    justify-content: space-between;

    &__image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__text {
      padding: $spacing-xl 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;

      .piBtn {
        align-self: flex-end;
      }

      .fit-in-grid & {
        padding-right: 0;
      }
    }

    /* ratios */
    &--half {
      .piImageText {
        &__image {
          flex-basis: calc(40% - 60px);
        }
        &__text {
          flex-basis: 60%;
          padding-top: $spacing-xl;
        }

      }
    }

    &--img-third {
      .piImageText {

        /* stylelint-disable-next-line no-descending-specificity */
        &__image {
          flex-basis: 40%;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        &__text {
          flex-basis: 60%;
        }
      }
    }

    &--switch {
      &-small {
        .piImageText {
          display: flex;

          /* stylelint-disable-next-line no-descending-specificity */
          &__image {
            order: 2;
          }

          /* stylelint-disable-next-line no-descending-specificity */
          &__text {
            order: 1;
          }
        }
      }

      &-medium {
        @include breakpoint(medium) {
          .piImageText {
            &__image {
              order: 2;
            }

            &__text {
              order: 1;
            }
          }
        }

        .fit-in-grid & {
          .piImageText__text {
            padding-left: 0;
            padding-right: $spacing-xl;
          }
        }
      }
    }
  }

  @include breakpoint(xlarge) {
    &__text {
      padding: $spacing-xl 0;
    }
  }
}

/* purgecss end ignore */
