@import "../config";
@import "../common/mixins";

$logo-height-large: 52px;
$logo-width-large: 180px;
$logo-width-small: 130px;
$logo-height-small: 38px;

.piHeader {
  position: relative;
  width: 100%;
  z-index: 10;
  background: $color-bg-light;
  //max-width: $max-page-width;
  margin: 0 auto;
  padding: $spacing-m;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: $content-width;
    margin: 0 auto;
  }

  h1 {
    margin: 0 auto 0 0;
    padding: 0;
    position: relative;
    height: $logo-height-small;
    width: $logo-width-small;

    span {
      display: none;
    }

    > a {
      display: block;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__menu {
    display: none;
  }

  &__menu-toggle {
    position: fixed;
    right: $spacing-xs;
    top: $spacing-xs*1.5;
    z-index: $zindex-level-2;
    width: 50px;
    height: 50px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: $color-bg-light;
      width: 100%;
      height: 100%;
      transition: background-color .3s ease-out;
    }

    &:hover {
      &::before {
        animation: hoverscale .3s ease-out;
      }
    }
  }

  &.is-open {
    z-index: $zindex-level-4;
    .piHeader {
      &__menu-toggle {
        &::before {
          background-color: $color-bg;
        }
      }
    }
  }

  @include breakpoint(large) {
    padding: 0 $spacing-m 0 $spacing-m;

    &__wrapper {
      padding: 0 $spacing-s;
    }

    h1 {
      height: $logo-height-large;
      width: $logo-width-large;
      margin: $spacing-m auto $spacing-m 0;
    }

    &__menu-toggle {
      transition: transform .3s ease-out;
      transform: scale(0);
      transform-origin: center center;
      right: $spacing-xl;
      top: $spacing-xl;
      z-index: 200;
    }

    &__menu {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      font-size: $font-size-xs;
      text-transform: uppercase;
      letter-spacing: rem(2);
      margin: 0;

      li {
        padding: rem(5) 0;
        margin: 0 $spacing-l;
        position: relative;
        overflow: hidden;

        &:last-of-type {
          margin-right: 0;
        }

        &::after {
          content: '';
          position: absolute;
          width: 0%;
          height: 1px;
          left: 50%;
          bottom: 0;
          background: $color-text;
          transition: height .3s ease, width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &:hover,
        &.is-active {
          a {
            color: $color-text;
          }

          &::after {
            width: 100%;
            left: 0;
            right: auto;
          }
        }

        &.is-active {
          font-weight: $font-weight-bold;

          &::after {
            width: 100%;
            height: 2px;
          }
        }
      }
    }

    &.out-of-view {
      .piHeader__menu-toggle {
        transform: scale(1);
      }
    }

    &.is-open {
      height: auto;

      .piHeader__menu-toggle {
        transform: scale(1);
      }
    }
  }


  @include breakpoint(small only) {
  }

  &--inverted {
    color: $color-white;

    .piLogo-content {
      &--default {
        display: none;
      }

      &--inverted {
        display: block;
      }
    }

    .piLogoAnim-inner,
    .piLogoAnim:hover .piLogoAnim-inner {
      &::before,
      &::after {
        background-color: $color-text !important;
      }
    }

    .piHeader__menu {
      li {
        &::after {
          background: $color-white;
        }

        &:hover {
          a {
            color: $color-white;
          }
        }
      }
    }
  }
}

@keyframes underline-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes underline-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}
