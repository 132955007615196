@import "../config";
@import "../common/mixins";

@mixin dropdown() {
  height: rem(60);
  border: 1px solid $color-lines;
  border-radius: $border-radius-m;
  background: url('../../img/chevron-down.svg') no-repeat;
  background-position: right -1rem center;
  background-size: rem(12) rem(12);
  background-origin: content-box;
  padding: 0 rem(40) 0 rem(18);
}

form {
  text-align: left;
}

label {
  transition: color 0.3s;
  font-size: 14px;

  span {
    color: $color-text-faded;

    &.text {
      color: $color-text;
    }

    &.infotext {
      display: block;
      font-size: $font-size-xs;
      color: $color-text;
    }
  }

  .piIconTooltip {
    width: rem(19);
    height: rem(19);
    z-index: 20;

    .piIcon {
      width: rem(28);
      height: rem(28);

      svg {
        fill: $color-warm-gray;
      }
    }
  }
}

.error-message {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s;
}

div.piError {
  input,
  .piInput--password__icon,
  .piCheckbox-checkmark,
  .piRadio-radiomark,
  textarea {
    border-color: $color-accent;
  }

  input:focus + .piInput--password__icon {
    box-shadow: none;
    border-color: #dcd6d1;
  }

  label > input,
  label > .input-group,
  textarea {
    margin-bottom: $spacing-xxs;
  }

  .error-message {
    font-size: $font-size-xs;
    color: $color-accent;
    margin-bottom: $spacing-m;
    height: auto;
    opacity: 1;
    overflow: auto;
  }

  &.piRadio {
    label {
      flex-wrap: wrap;

      .error-message {
        flex-basis: 100%;
        margin-bottom: 0;
      }
    }
  }
}

.piSelect {
  font-size: $font-size-s;
  @include dropdown();
}

.piForm {
  text-align: left;

  .piSelect,
  &__wrapper {
    margin-bottom: $spacing-m;
  }

  .piRadio,
  .piCheckbox {
    margin-bottom: $spacing-xs;
  }

  &__subtitle {
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-m;
  }

  &__actions {
    button {
      width: 100%;
      margin-top: $spacing-s;
    }
  }

  @include breakpoint(medium) {
    display: flex;
    flex-wrap: wrap;
    @include grid-flex-wrapper(10);

    > .piInput,
    > .piSelect,
    > .piTextarea,
    &__wrapper {
      margin-left: 10px;
      margin-right: 10px;
      flex-grow: 1;
      flex-basis: calc(50% - 20px);

      &.col {
        &-one-third {
          flex-basis: calc(40% - 20px);
        }

        &-two-thirds {
          flex-basis: calc(60% - 20px);
        }

        &-full {
          flex-basis: calc(100% - 20px);
        }
      }
    }

    .piForm__actions {
      display: flex;
      justify-content: space-between;

      button {
        width: auto;
        min-width: rem(300);
        margin-top: 0;
      }
    }
  }

  &--contact {
    @include breakpoint(large) {
      max-width: 70%;
      margin: auto;
    }
  }
}
