@import "../config";
@import "../common/mixins";

.piNavFlyout {
  max-height: 100%;
  display: block;
  overflow: hidden;
  z-index: $zindex-level-1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform .3s linear .1s, background-color .3s linear .6s;
  transform: translateY(-100%);
  color: $color-text;
  height: 100%;

  .wrapped {
    height: 100%;
  }

  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  &__contact {
    margin-top: $spacing-xxl;

    &__divider {
      margin: $spacing-xxl 0 $spacing-m;
    }
  }

  &::after {
    position: absolute;
    content: '';
    bottom: -3px;
    height: 3px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
  }

  h1 {
    display: none;
  }

  &__menu {
    @include fade-in($order: 1);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      line-height: 2.2;

      li {
        font-family: $font-decor;
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        letter-spacing: $letter-spacing;
        text-align: center;

        &.is-active {
          opacity: .3;
        }
      }
    }

    span {
      display: block;
      color: $color-text-faded;
      font-size: $font-size-s;
    }
  }

  &.is-active {
    background: $color-bg;
    transform: translateY(0);
    transition: transform .3s linear 0s, background-color .1s linear 0s;
  }
}
