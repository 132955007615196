@import '../config';

/*! purgecss start ignore */
#ccc {
  .ccc-banner {
    margin: $spacing-xs;
    pointer-events: all;
    padding: 0;
    &::before {
      content: '';
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1001;
      pointer-events: none;
    }
    &__text,
    &__form {
      background: $color-white;
      z-index: 1002;
      padding: $spacing-m;
      position: relative;
      > * {
        z-index: 1002;
      }
    }
    &__text {
      padding-bottom: $spacing-s;
    }
    &__form {
      padding-top: 0;
    }
    &__title {
      font-size: $font-size-l;
      margin-bottom: $spacing-xxs;
    }
    &__description {
      font-size: $font-size-m;
      line-height: $line-height-normal;
    }
    &__label-container {
      display: flex;
      flex-wrap: wrap;
      label {
        margin-right: $spacing-m;
        > [type='checkbox'],
        > [type='radio'] {
          margin-right: $spacing-xs;
        }
      }
    }
    &__button {
      margin-top: $spacing-s;
      border-radius: 0;
      font-size: $font-size-xs;
      letter-spacing: 1px;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      box-shadow: none;
      background-color: $color-text;
    }
    &--bottom {
      @include breakpoint(large) {
        max-width: 45%;
        right: auto;
      }
    }
  }
}

/*! purgecss end ignore */
