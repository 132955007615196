@import "../config";

$spacing-xsmall-s: rem(5);
$spacing-small-s: rem(10);
$spacing-medium-s: rem(20);
$spacing-large-s: rem(40);
$spacing-xlarge-s: rem(80);

$spacing-xsmall: rem(10);
$spacing-small: rem(20);
$spacing-medium: rem(40);
$spacing-large: rem(60);
$spacing-xlarge: rem(100);

/* purgecss start ignore */
.spacing {
  &-xsmall {
    &-all {
      padding: $spacing-xsmall-s;
    }

    &-vertical {
      padding-top: $spacing-xsmall-s;
      padding-bottom: $spacing-xsmall-s;
    }

    &-horizontal {
      padding-left: $spacing-xsmall-s;
      padding-right: $spacing-xsmall-s;
    }

    &-top {
      padding-top: $spacing-xsmall-s;
    }

    &-bottom {
      padding-bottom: $spacing-xsmall-s;
    }

    &-left {
      padding-left: $spacing-xsmall-s;
    }

    &-right {
      padding-right: $spacing-xsmall-s;
    }
  }

  &-small {
    &-all {
      padding: $spacing-small-s;
    }

    &-vertical {
      padding-top: $spacing-small-s;
      padding-bottom: $spacing-small-s;
    }

    &-horizontal {
      padding-left: $spacing-small-s;
      padding-right: $spacing-small-s;
    }

    &-top {
      padding-top: $spacing-small-s;
    }

    &-bottom {
      padding-bottom: $spacing-small-s;
    }

    &-left {
      padding-left: $spacing-small-s;
    }

    &-right {
      padding-right: $spacing-small-s;
    }
  }

  &-medium {
    &-all {
      padding: $spacing-medium-s;
    }

    &-vertical {
      padding-top: $spacing-medium-s;
      padding-bottom: $spacing-medium-s;
    }

    &-horizontal {
      padding-left: $spacing-medium-s;
      padding-right: $spacing-medium-s;
    }

    &-top {
      padding-top: $spacing-medium-s;
    }

    &-bottom {
      padding-bottom: $spacing-medium-s;
    }

    &-left {
      padding-left: $spacing-medium-s;
    }

    &-right {
      padding-right: $spacing-medium-s;
    }
  }

  &-large {
    &-all {
      padding: $spacing-large-s;
    }

    &-vertical {
      padding-top: $spacing-large-s;
      padding-bottom: $spacing-large-s;
    }

    &-horizontal {
      padding-left: $spacing-large-s;
      padding-right: $spacing-large-s;
    }

    &-top {
      padding-top: $spacing-large-s;
    }

    &-bottom {
      padding-bottom: $spacing-large-s;
    }

    &-left {
      padding-left: $spacing-large-s;
    }

    &-right {
      padding-right: $spacing-large-s;
    }
  }

  &-xlarge {
    &-all {
      padding: $spacing-xlarge-s;
    }

    &-vertical {
      padding-top: $spacing-xlarge-s;
      padding-bottom: $spacing-xlarge-s;
    }

    &-horizontal {
      padding-left: $spacing-xlarge-s;
      padding-right: $spacing-xlarge-s;
    }

    &-top {
      padding-top: $spacing-xlarge-s;
    }

    &-bottom {
      padding-bottom: $spacing-xlarge-s;
    }

    &-left {
      padding-left: $spacing-xlarge-s;
    }

    &-right {
      padding-right: $spacing-xlarge-s;
    }
  }

  @include breakpoint(medium) {
    &-xsmall {
      &-all {
        padding: $spacing-xsmall;
      }

      &-vertical {
        padding-top: $spacing-xsmall;
        padding-bottom: $spacing-xsmall;
      }

      &-horizontal {
        padding-left: $spacing-xsmall;
        padding-right: $spacing-xsmall;
      }

      &-top {
        padding-top: $spacing-xsmall;
      }

      &-bottom {
        padding-bottom: $spacing-xsmall;
      }

      &-left {
        padding-left: $spacing-xsmall;
      }

      &-right {
        padding-right: $spacing-xsmall;
      }
    }

    &-small {
      &-all {
        padding: $spacing-small;
      }

      &-vertical {
        padding-top: $spacing-small;
        padding-bottom: $spacing-small;
      }

      &-horizontal {
        padding-left: $spacing-small;
        padding-right: $spacing-small;
      }

      &-top {
        padding-top: $spacing-small;
      }

      &-bottom {
        padding-bottom: $spacing-small;
      }

      &-left {
        padding-left: $spacing-small;
      }

      &-right {
        padding-right: $spacing-small;
      }
    }

    &-medium {
      &-all {
        padding: $spacing-medium;
      }

      &-vertical {
        padding-top: $spacing-medium;
        padding-bottom: $spacing-medium;
      }

      &-horizontal {
        padding-left: $spacing-medium;
        padding-right: $spacing-medium;
      }

      &-top {
        padding-top: $spacing-medium;
      }

      &-bottom {
        padding-bottom: $spacing-medium;
      }

      &-left {
        padding-left: $spacing-medium;
      }

      &-right {
        padding-right: $spacing-medium;
      }
    }

    &-large {
      &-all {
        padding: $spacing-large;
      }

      &-vertical {
        padding-top: $spacing-large;
        padding-bottom: $spacing-large;
      }

      &-horizontal {
        padding-left: $spacing-large;
        padding-right: $spacing-large;
      }

      &-top {
        padding-top: $spacing-large;
      }

      &-bottom {
        padding-bottom: $spacing-large;
      }

      &-left {
        padding-left: $spacing-large;
      }

      &-right {
        padding-right: $spacing-large;
      }
    }

    &-xlarge {
      &-all {
        padding: $spacing-xlarge;
      }

      &-vertical {
        padding-top: $spacing-xlarge;
        padding-bottom: $spacing-xlarge;
      }

      &-horizontal {
        padding-left: $spacing-xlarge;
        padding-right: $spacing-xlarge;
      }

      &-top {
        padding-top: $spacing-xlarge;
      }

      &-bottom {
        padding-bottom: $spacing-xlarge;
      }

      &-left {
        padding-left: $spacing-xlarge;
      }

      &-right {
        padding-right: $spacing-xlarge;
      }
    }
  }
}

/* purgecss end ignore */
