@import "../config";

/* purgecss start ignore */
.piAwards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $spacing-m;
  &__item {
    border-top: 1px solid $color-border;
    padding: $spacing-l 0;
    line-height: $line-height-large;
    margin-top: -1px;
    width: 50%;
    flex-grow: 1;

    &__name {
      font-weight: $font-weight-medium;
    }
  }

  @include breakpoint(small only) {
    &__item {
      width: 100%;
    }
  }
}

/* purgecss end ignore */
