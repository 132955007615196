@import "../config";

/* purgecss start ignore */
#piMap {
  width: 100%;
  height: 500px;
}


/* purgecss end ignore */
