/**
 * components/global.css
 *
 * Project-wide styles
 *
 */
@import "../config";
@import "mixins";

/* purgecss start ignore */
html {
  font-size: $font-size-m;
  box-sizing: border-box;
  text-size-adjust: 100%;
  height: 100%;

  @include breakpoint(medium) {
    font-size: $font-size-l;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html.lock {
  overflow: hidden;
  margin: 0;
  max-height: 100%;
}

@include breakpoint(small only) {
  html.menu-open {
    overflow: hidden;

    body {
      overflow: hidden;
      height: 0;
    }
  }
}

body {
  margin: 0 auto;
  line-height: $line-height-normal;
  font-synthesis: none;
  font-family: $font-primary;
  font-size: $font-size-m;
  letter-spacing: 0.02em;
  color: $color-text;
  background-color: $color-ultra-light;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga', 'kern';

  .lock & {
    overflow: hidden;
    height: 0;
  }

  > * {
    transition: opacity .3s;
  }

  main {
    position: relative;
    margin: 0 auto;
  }
}

a,
button,
label {
  @include remove-tap-highlight();

  outline: 0;
}

section,
div {
  text-align: left;
  position: relative;
  max-width: 100%;

  &.colored {
    background: $color-bg;

    &-light {
      background: $color-bg-light;

      &.shadow {
        box-shadow: inset 0 -10vh 0 0 #ffffff;
      }
      &.shadow-down {
        box-shadow: 0 10vh 0 0 $color-bg-light;
      }

      @include breakpoint(large) {

        &.shadow {
          box-shadow: inset 0 -50vh 0 0 #ffffff;
        }
        &.shadow-down {
          box-shadow: 0 10vh 0 0 $color-bg-light;
        }

      }
    }
  }

  &.wrapped {
    margin-left: auto;
    margin-right: auto;
    max-width: $content-width;
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
  }

  &.divider {
    @include divider($spacing-xxl);
  }

  &.stage {
    background: $color-ultra-light;
  }

  &.horizontal-scroll {
    -webkit-overflow-scrolling: touch;
  }

  &.center {
    text-align: center;
  }

  @include breakpoint(small only) {
    &.wrapped {
      padding-left: $spacing-m;
      padding-right: $spacing-m;

      &.inside,
      &.padding-on-small {
        padding-left: $spacing-m;
        padding-right: $spacing-m;
      }
    }

    &.horizontal-scroll {
      padding-left: 0;
      padding-right: 0;

      .grid-margin-x {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.alternate {
      background: $color-light-gray;
      padding-top: $spacing-xl;
      padding-bottom: $spacing-xl;
    }

    &.divider {
      padding-bottom: 0;
    }
  }

  @include breakpoint(medium) {
    &.inside {
      width: calc(100% - 40px);

      .wrapped.outside & {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @include breakpoint(large) {
    &.wrapped {
      margin-left: auto;
      margin-right: auto;
      max-width: $content-width;
      padding-left: $spacing-m;
      padding-right: $spacing-m;
    }

    &.inside {
      max-width: 66.66%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include breakpoint(large) {
    &.inside {
      max-width: 80%;
    }
  }
  @include breakpoint(xlarge) {
    &.inside {
      max-width: 90%;
    }
  }
}

.image-wrapper,
.piImagegrid__item {
  position: relative;
  img {
  }
  .image-copyright {
    position: absolute;
    right: 0;
    border-bottom: 0;
    background-color: rgba(0,0,0,0.3);
    color: $color-white;
    font-size: $font-size-xxs;
  }
}

.hidden {
  display: none !important;
}

/* purgecss end ignore */
