@import "../config";

.piProject {
  margin: $spacing-s 0;
  position: relative;

  > a span {
    display: block;
  }

  &__image {
    overflow: hidden;
    position: relative;

    .piImage {
      transition: 0.5s ease;
    }
  }

  &__text {
    padding: $spacing-xs 0;
    font-size: $font-size-s;

    &__place {
      color: $color-text-faded;
      font-size: $font-size-s;
    }

    &__title {
      font-weight: $font-weight-bold;
    }
  }

  &__reveal {
    display: none !important;
  }

  @include breakpoint(medium) {
    &__text {
      padding: $spacing-s 0;
    }

    &__reveal {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba($color-accent, .8);
      display: flex !important;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transform: translateY(50px);
      transition: opacity ease-out .3s, transform ease-out .3s;

      &__text {
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        color: $color-white;
        font-size: $font-size-xs;
        letter-spacing: $letter-spacing;
      }
    }

    &:hover {
      .piProject__reveal {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__outline {
    font-size: $font-size-s;
  }

  .main--inverted & {
    .piProject__text__category {
      color: $color-white;
      opacity: 0.7;
    }
  }

  &Detail {
    line-height: $line-height-large;

    .quelle {
      font-size: $font-size-s;
      color: $color-text-faded;
    }

    &__facts {
      > div > p {
        font-size: $font-size-xs;
        line-height: $line-height-large;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        text-decoration: underline;
      }

      .piImage {
        margin-bottom: $spacing-xs;
        width: 100%;
      }
    }

    @include breakpoint(medium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__facts {
        order: 1;
        flex-basis: 25%;
        font-size: $font-size-xs;
      }

      &__content {
        order: 2;
        flex-basis: 65%;
        width: 65%;
        position: relative;
      }
    }
  }
}

/* purgecss start ignore */

.piProjectIntro {
  @include breakpoint(medium) {
    position: relative;
  }
}

.piProjectList {
  @include breakpoint(medium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .piProject {
      flex: 0 0 calc(50% - 10px);
    }
  }

  @include breakpoint(large) {
    padding: 0;
    justify-content: flex-start;

    .piProject {
      flex-basis: calc(40.3% - 20px);
      margin: $spacing-m $spacing-s $spacing-m 0;

      &--wide {
        //flex-basis: calc(50% - 20px);
      }

      &--shift {
        margin-left: 10.4%;
      }
    }
  }
}

/* purgecss end ignore */

.back-to-overview {
  display: none;

  @include breakpoint(medium) {
    display: block;
    position: absolute;
    left: rem-calc(30);
    top: rem-calc(40);
  }

  @include breakpoint(large) {
    left: rem-calc(40);
  }
}

@include breakpoint(medium) {
  .wrapped.outside {
    width: $el-width-menu-safe;
  }
}

main.main--inverted .back-to-overview {
  .piArrow__wrapper {
    &::after {
      border-color: $color-white;
    }

    &::before {
      background-color: $color-white;
    }
  }
}
