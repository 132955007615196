@import "../config";
@import "mixins";

/*
 * HTML-Template
    <div class="scale-container small-ratio-1by1 medium-ratio-16by12 large-ratio-16by9 source-ratio-16by9">
      <div class="element-scaler">
        <ELM class="scale-target"></ELM>
      </div>
    </div>
 */

/* purgecss start ignore */
$sourceRatios: (
  'ratio-16by9' : 9,
  'ratio-16by8' : 8,
  'ratio-1by1' : 16,
  'ratio-16by14' : 14,
  'ratio-16by12' : 12,
  'ratio-10by16' : (16/10 * 16),
  /*
  'ratio-16by10' : 10,
  'ratio-16by12' : 12,
  'ratio-16by18' : 18,
  'ratio-5by6'  : (16/5 * 6),
  'ratio-5by8'  : (16/5 * 8),
  'ratio-5by7'  : (16/5 * 7),
  */
);

$targetRatios: (
  'ratio-1by1' : 16,
  'ratio-16by8' : 8,
  'ratio-16by9' : 9,
  'ratio-16by10' : 10,
  'ratio-16by14' : 14,
  'ratio-16by12' : 12,
  'ratio-16by13' : 13,
  'ratio-10by16' : (16/10 * 16),
  'ratio-3by4' : (16/3 * 4),
);

@mixin scale-container-html-classes($sizeName, $ratioName, $ratioValue) {
  &.#{$sizeName}-#{$ratioName} {
    padding-top: $ratioValue/16 * 100%;

    @each $sourceRatioName, $sourceRatioValue in $sourceRatios {
      &.source-#{$sourceRatioName} {
        @if (16/$ratioValue) >= (16/$sourceRatioValue) {
          .scale-target {
            top: 0.5 * (1 - $sourceRatioValue/$ratioValue) * 100%;
          }

          .element-scaler {
            width: 100%;
            left: 0;
          }
        }

        @else {
          .scale-target {
            top: 0;
          }

          .element-scaler {
            width: $ratioValue/$sourceRatioValue * 100%;
            left: -1 * 0.5 * (($ratioValue/$sourceRatioValue * 100%) - 100%);
          }
        }
      }
    }
  }
}

.scale-container {
  position: relative;
  overflow: hidden;
  width: 100%;

  @each $ratioName, $ratioValue in $targetRatios {
    @media #{$small-up} {
      @include scale-container-html-classes($sizeName: 'small', $ratioName: $ratioName, $ratioValue: $ratioValue);
    }
  }
  @each $ratioName, $ratioValue in $targetRatios {
    @media #{$medium-up} {
      @include scale-container-html-classes($sizeName: 'medium', $ratioName: $ratioName, $ratioValue: $ratioValue);
    }
  }
  @each $ratioName, $ratioValue in $targetRatios {
    @media #{$large-up} {
      @include scale-container-html-classes($sizeName: 'large', $ratioName: $ratioName, $ratioValue: $ratioValue);
    }
  }

  @each $ratioName, $ratioValue in $targetRatios {
    @media #{$xlarge-up} {
      @include scale-container-html-classes($sizeName: 'xlarge', $ratioName: $ratioName, $ratioValue: $ratioValue);
    }
  }

  @each $ratioName, $ratioValue in $targetRatios {
    @media #{$xxlarge-up} {
      @include scale-container-html-classes($sizeName: 'xxlarge', $ratioName: $ratioName, $ratioValue: $ratioValue);
    }
  }

  .element-scaler {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .scale-target {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
  }

  &.focal-point-left {
    .element-scaler {
      left: 0 !important;
    }
  }

  &.focal-point-right {
    .element-scaler {
      right: 0 !important;
    }
  }

  &.focal-point-top {
    .scale-target {
      top: 0 !important;
    }
  }

  &.focal-point-bottom {
    .scale-target {
      bottom: 0 !important;
      top: auto !important;
    }
  }

  &.no-scale {
    .element-scaler {
      position: static;
    }

    .scale-target {
      position: static;
    }
  }

  &.show-overflow {
    overflow: visible;
  }
}

/* purgecss end ignore */
