@import '../config';

$_spinner-size: rem(40px);

/*! purgecss start ignore */
.loading-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: $color-accent;
  transition: visibility 0.3s, opacity 0.3s, background-color 1s;
  will-change: visibility, opacity, background-color;
  z-index: 100000;

  &.loading-layer--active {
    visibility: visible;
    opacity: 1;
  }

  &.loading-layer--opaque {
    visibility: visible;
    opacity: 1;
    background-color: $color-accent;
  }

  &-spinner {
    width: $_spinner-size;
    height: $_spinner-size;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    margin: auto;

    &__content {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #333;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      animation: sk-bounce 2s infinite ease-in-out;
    }
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

/*! purgecss end ignore */
