@import "../config";

.piProjectFilter {
  position: relative;
  width: 100%;
  background: $color-white;
  padding: $spacing-m;
  margin-top: $spacing-s;
  box-shadow: $boxshadow-regular;

  &__group {
    &__label {
      text-transform: uppercase;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      margin-bottom: $spacing-xs;
    }

    &__items {
      > select {
        margin-bottom: rem(5);
      }
    }
  }

  &__active {
    padding-top: $spacing-m;

    &__item {
      display: inline-block;
      background: $color-bg-light;
      border-radius: rem(40);

      > a {
        padding: rem(15) rem(20);
        display: inline-block;
        font-size: $font-size-s;

        &::after {
          display: inline-block;
          content: 'x';
          margin-left: $spacing-xs;
        }
      }
    }

    &__reset {
      display: inline-block;

      > a {
        padding: rem(15) rem(20);
        display: inline-block;
        font-size: $font-size-s;
        text-decoration: underline;
      }
    }
  }

  @include breakpoint(medium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: $spacing-l;

    &__group {
      flex-grow: 1;

      &__label {
        margin: 0 rem(5) $spacing-s;
      }

      &__items {
        display: flex;
        justify-content: space-between;

        > select {
          margin: 0 rem(5);
        }
      }
    }

    &__active {
      flex-basis: 100%;
    }
  }
}

#toggleFilter {
  padding: rem(10) 0;
  border-bottom: 1px solid $color-text;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  span {
    width: rem(10);
    height: rem(10);
    margin-left: $spacing-xs;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url('../../img/filter.svg');
  }

  @include breakpoint(large) {
    position: absolute;
    right: $spacing-m;
    top: 0;
  }

  &.active {
    span {
      background-image: url('../../img/close.svg');
    }
  }

  &:hover {
    cursor: pointer;
  }
}

#toggleFilterTarget {
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;

  &.active {
    max-height: 100%;
    opacity: 1;
    transition: all 0.3s linear;
    margin-bottom: $spacing-l;
  }
}
