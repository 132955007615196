@mixin fade-element(
  $duration: 0.3s,
  $delay: 0.3s,
  $order: 1,
  $easing: ease-out
) {
  opacity: 0;
  transform: translateY(-50px);

  .is-active & {
    $element-delay: $delay * $order;

    opacity: 1;
    transform: translateY(0);
    transition: opacity $duration $element-delay $easing,
    transform $duration $element-delay $easing;
  }
}

.inview {
  @include fade-element();

  border: 40px solid red;

  .no-csstransitions &,
  .no-cssanimations & {
    opacity: 1;
    transform: translateY(0);
  }
}

/**
 * Add will-change hint for aos-animate elements
 */

/*! purgecss start ignore */
[data-aos].aos-animate {
  will-change: transform, opacity;
}

/*! purgecss end ignore */

/* EASING */

/* Quad (1)  */
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-power1: $ease-in-quad;
$ease-out-power1: $ease-out-quad;
$ease-in-out-power1: $ease-in-out-quad;

/* Cubic (2) */
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-power2: $ease-in-cubic;
$ease-out-power2: $ease-out-cubic;
$ease-in-out-power2: $ease-in-out-cubic;

/* Quart (3) */
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-power3: $ease-in-quart;
$ease-out-power3: $ease-out-quart;
$ease-in-out-power3: $ease-in-out-quart;

/* Quint (4) */
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-power4: $ease-in-quint;
$ease-out-power4: $ease-out-quint;
$ease-in-out-power4: $ease-in-out-quint;

/* Back */
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

/* Circ */
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

/* Expo */
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

/* Sine */
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

/*! purgecss start ignore */
.effect {
  &--rotate {
    position: relative;
    width: rem(300);
    height: rem(300);

    > svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

/*! purgecss end ignore */
