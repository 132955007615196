@import '../config';

/*! purgecss start ignore */

.page-transition {
  position: fixed;
  width: 100vw;
  height: 200vh;
  pointer-events: none;
  background-color: $color-bg;
  z-index: $zindex-level-3;
  visibility: hidden;
  opacity: 0;
}

/* Active state, define the transition,
here, same for leave and enter */
.fade-leave-active,
.fade-enter-active,
.slide-leave-active,
.slide-enter-active {
  transition: opacity 450ms ease, transform 650ms ease-in-out;
}

/* Fade states */
.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-to {
  transform: translateY(-50px);
}

/* Slide states */
.slide-leave,
.slide-enter-to {
  transform: translateX(0);
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

/* Overlap states */

/* transition active state (same for leave and enter) */
.overlap-leave-active,
.overlap-enter-active {
  transition: opacity 800ms ease;
  transition-delay: 0.3s;
}

/* initial state */
.overlap-leave {
  opacity: 1;
}

.overlap-enter {
  opacity: 0;
}

/* ending state */
.overlap-leave-to {
  opacity: 0;
}

.overlap-enter-to {
  opacity: 1;
}

[data-aos='info'] {
  opacity: 0;
  transition-property: opacity;

  &.aos-animate {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  0% {
    transform: scale(1);
  }
}

/*! purgecss end ignore */
