@import "../config";

#piIntro {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $color-beige;
  transform: translateY(-120%);
  transition: transform 0.5s ease-in-out;
  z-index: $zindex-level-3;

  .piIntro__code {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .piIntro__credit {
    position: absolute;
    font-size: $font-size-s;
    right: $spacing-m;
    bottom: $spacing-m;

    > a {
      text-decoration: underline;
      text-align: right;
      color: $color-white;
    }
  }

  &.is-active {
    transform: translateY(0);
    transition: none;
  }
}
