/**
 * pages/index.pcss
 *
 * Styles for the Home page.
 *
 */

@import "../config";
@import "../common/mixins";

body {
  background-color: white;
}

.pi-logo {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    max-width: 20vw;
    max-height: 50vh;
  }
}

.piIntro {
  box-shadow: inset 0px -80px 0px 0 #ffffff;
  &__image {
    margin-bottom: $spacing-s;
    z-index: 20;
  }

  .piBtn {
    margin-bottom: $spacing-m;
  }

  &__wrapper {
    display: flex;
  }

  @include breakpoint(medium down) {
    background-color: $color-bg-light;

    &__wrapper {
      flex-wrap: wrap;
    }

    &__text {
      order: 2;
      padding-bottom: $spacing-l;
    }

    &__image {
      order: 1;
      width: calc(100% + 50px);
      max-width: calc(100% + 50px);
      position: relative;
      margin: 0 $spacing-m*-1 $spacing-m;
    }
  }

  @include breakpoint(medium only) {
    &__image {
      width: calc(100% + 80px);
      max-width: calc(100% + 80px);
      margin: 0 $spacing-xl*-1 $spacing-m;
    }
  }

  @include breakpoint(large) {
    &__wrapper {
      justify-content: space-between;
    }

    &__text {
      width: 50%;
      padding: $spacing-xxxl 0 0;
    }

    &__image {
      width: 45%;
      right: 0;
    }
  }
}
