@import "../config";

/* purgecss start ignore */
.piFakten {
  display: flex;
  justify-content: space-between;
  &__item {
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    padding: $spacing-m 0 $spacing-l;
    text-align: center;
    width: auto;
    flex-grow: 1;

    &__wert {
      font-weight: $font-weight-medium;
      font-size: $font-size-title-xxl;
      text-align: center;
    }

    &__name {
      text-transform: uppercase;
      font-size: $font-size-s;
      text-align: center;
    }

  }
}

/* purgecss end ignore */
