img {
  position: relative;

  a {
    display: block;
    border: none;
  }

  &.blur-up {
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
  }

  &.blur-up.lazyloaded {
    filter: blur(0);
  }
}

.piImage {
  display: block;
}
