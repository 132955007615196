@import "../config";

/* purgecss start ignore */
.piJobsListing {
  &__item {
    display: flex;
    align-items: center;
    border-top: 1px solid $color-border;
    padding: $spacing-m 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;

    &:first-of-type {
      border-top: none;
    }

    &:hover {
      color: $color-text-faded;
    }
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36' height='36'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

/* purgecss end ignore */
